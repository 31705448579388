import ModalDialog from './ModalDialog'
import { TemplateResult, html } from 'lit-html'

export default class MessageBox extends ModalDialog {

    constructor(private title: string, private message: string) {
        super()
    }

    createContent(): TemplateResult {
        return html`
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">${this.title}</h5>
        </div>
        <div class="modal-body">
            <p>${this.message}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
        </div>
        `
    }


}