import CampaignListView from 'campaign/CampaignListView'
import { html, render } from 'lit-html'
import Router from 'Router'
import Session from 'Session'
import logo from '../../resources/images/logo.png'

export default class MainPage extends HTMLElement {

    private viewContainer!: HTMLDivElement

    constructor() {
        super()
    }

    public connectedCallback() {
        this.renderView()
        this.viewContainer = this.querySelector<HTMLDivElement>("#mainPageViewContainer")!

        Router.setRenderCallback((view) => this.showView(view))
    }

    private renderView() {
        render(html` 
<div class="kt-page">
    <div id="kt_header_mobile" class="kt-header-mobile  kt-header-mobile--fixed ">
        <div class="kt-header-mobile__logo">
            <a href="index.html">
                <img alt="Logo" src="assets/media/logos/logo-9-sm.png">
            </a>
        </div>
        <div class="kt-header-mobile__toolbar">
            <button class="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler"><span></span></button>
            <button class="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler"><i class="flaticon-more-1"></i></button>
        </div>
    </div>

    <div id="kt_header" class="kt-header  kt-header--fixed " data-ktheader-minimize="on">
        <div class="kt-container  kt-container--fluid ">

            <!-- begin: Header Menu -->
            <button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i class="la la-close"></i></button>
            <div class="kt-header-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_header_menu_wrapper">
                <div id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile ">
                    <ul class="kt-menu__nav ">
                        <li class="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel" data-ktmenu-submenu-toggle="click" aria-haspopup="true"><a href="#" class="kt-menu__link kt-menu__toggle" @click="${() => CampaignListView.show()}"><span class="kt-menu__link-text">Dashboard</span><i class="kt-menu__ver-arrow la la-angle-right"></i></a></li>
                    </ul>
                </div>
            </div>
            <!-- end: Header Menu -->

            <!-- begin:: Brand -->
            <div class="kt-header__brand   kt-grid__item" id="kt_header_brand">
                <a class="kt-header__brand-logo" href="?page=index">
                    <img alt="Logo" src="${logo}">
                </a>
            </div>
            <!-- end:: Brand -->

            <!-- begin:: Header Topbar -->
            <div class="kt-header__topbar kt-grid__item">

                <!--begin: User bar -->
                <div class="kt-header__topbar-item kt-header__topbar-item--user">
                    <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="10px,0px">
                        <span class="kt-header__topbar-welcome kt-visible-desktop">Willkommen,</span>
                        <span class="kt-header__topbar-username kt-visible-desktop">${Session.get().user?.firstName ?? ""}</span>

                        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
                        <span class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">${(Session.get().user?.firstName.substring(0, 1) ?? "") + (Session.get().user?.lastName.substring(0, 1) ?? "")}</span>
                    </div> 
                    <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl">
                        <!--begin: Navigation -->
                        <div class="kt-notification">
                            <div class="kt-notification__custom kt-space-between">
                                <button class="btn btn-label btn-label-brand btn-sm btn-bold right" @click=${() => this.logout()}>Abmelden</button>
                            </div>
                        </div>

                        <!--end: Navigation -->
                    </div>
                </div>

                <!--end: User bar -->
            </div>

            <!-- end:: Header Topbar -->
        </div>
    </div>
    <div id="mainPageViewContainer"></div>
</div>`, this)
    }

    private showView(view: HTMLElement) {
        this.viewContainer.innerHTML = ""
        this.viewContainer.appendChild(view)
    }

    private logout() {
        Session.get().invalidate()
        Router.navigate("")
    }
}

customElements.define("mo-page", MainPage)