export const GermanTranslation = {
    "save": "Speichern",
    "cancel": "Abbrechen",

    "organisation.name": "Name",
    "organisation.street": "Strasse",
    "organisation.postalcode": "Postleitzahl",
    "organisation.city": "Ort",
    "organisation.phonenumber": "Telefonnummer",

    "user.mailaddress": "E-Mailadresse",
    "user.firstname": "Vorname",
    "user.lastname": "Nachname",
    "user.phonenumber": "Telefonnummer",
    "user.password": "Passwort",
    "user.isAdmin": "Administrator",

    "addressStandardColumn.company_name": "Firmenname",
    "addressStandardColumn.salutation": "Anrede",
    "addressStandardColumn.first_name": "Vorname",
    "addressStandardColumn.last_name": "Nachname",
    "addressStandardColumn.street": "Strasse",
    "addressStandardColumn.post_office_box_number": "Postfach",
    "addressStandardColumn.postcode": "Postleitzahl",
    "addressStandardColumn.street_number": "Hausnummer",
    "addressStandardColumn.city": "Ort",
    "addressStandardColumn.language": "Sprache",
    "addressStandardColumn.full_name": "Vor- und Nachname",
    "addressStandardColumn.street_and_number": "Strasse und Hausnummer",
    "addressStandardColumn.postcode_and_city": "Postleitzahl und Ort",

    "addressStandardColumn.customer_number": "Kundennummer",
    "addressStandardColumn.sort": "Sorten",

    "addressStandardColumn.title": "Titel",
    "addressStandardColumn.company_additional": "Firmenzusatz",
    "addressStandardColumn.co_address": "c/o Adresse",
    "addressStandardColumn.country": "Land",
    "addressStandardColumn.email": "E-Mail",
    "addressStandardColumn.phone": "Telefon",
    "addressStandardColumn.mobile_phone": "Mobile",
    "addressStandardColumn.letter_salutation": "Briefanrede",

    "addressStandardColumn.custom_char_1": "Frei CHAR 1",
    "addressStandardColumn.custom_char_2": "Frei CHAR 2",
    "addressStandardColumn.custom_char_3": "Frei CHAR 3",
    "addressStandardColumn.custom_char_4": "Frei CHAR 4",
    "addressStandardColumn.custom_char_5": "Frei CHAR 5",
    "addressStandardColumn.custom_char_6": "Frei CHAR 6",
    "addressStandardColumn.custom_char_7": "Frei CHAR 7",
    "addressStandardColumn.custom_char_8": "Frei CHAR 8",
    "addressStandardColumn.custom_char_9": "Frei CHAR 9",
    "addressStandardColumn.custom_char_10": "Frei CHAR 10",
    "addressStandardColumn.custom_num_1": "Frei NUM 1",
    "addressStandardColumn.custom_num_2": "Frei NUM 2",
    "addressStandardColumn.custom_num_3": "Frei NUM 3",
    "addressStandardColumn.custom_num_4": "Frei NUM 4",
    "addressStandardColumn.custom_num_5": "Frei NUM 5",
    "addressStandardColumn.custom_num_6": "Frei NUM 6",
    "addressStandardColumn.custom_num_7": "Frei NUM 7",
    "addressStandardColumn.custom_num_8": "Frei NUM 8",
    "addressStandardColumn.custom_num_9": "Frei NUM 9",
    "addressStandardColumn.custom_num_10": "Frei NUM 10",

    "campaign.next-action.created": "Datei hochladen",
    "campaign.next-action.file_uploaded": "Zurodnung erstellen",
    "campaign.next-action.value_mapping": "Wertezuordnungen erstellen",
    "campaign.next-action.mapping_done": "Freigeben",
    "campaign.next-action.ready_to_export": "Anschauen",
    "campaign.next-action.exported": "Anschauen",

    "campaign.status.created": "Angelegt",
    "campaign.status.file_uploaded": "Datei hochgeladen",
    "campaign.status.mapping_done": "Mapping erstellt",
    "campaign.status.value_mapping": "Feldzuordnung erstellt",
    "campaign.status.ready_to_export": "Freigabe erteilt",
    "campaign.status.exported": "Abgeschlossen",

}