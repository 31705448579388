import 'login/LoginPage'
import 'campaign/CampaignListView'
import 'campaign/CampaignCreateView'
import 'campaign/CampaignFileUploadView'
import 'campaign/CampaignFileSettingsView'
import 'campaign/CampaignMappingView'
import LoginPage from 'login/LoginPage'
import Session from 'Session'
import MainPage from 'base/MainPage'
import Router from 'Router'
import CampaignListView from 'campaign/CampaignListView'


export default class App {
    private viewContainer!: HTMLDivElement

    public start() {
        console.log("start app")

        this.viewContainer = document.querySelector<HTMLDivElement>("#viewContainer")!

        document.addEventListener("loggedIn", () => this.onLoggedInEvent())
        document.addEventListener("unauthorized", () => this.show(new LoginPage()))

        if (Session.get().isValid()) {
            this.show(new MainPage())
        } else {
            this.show(new LoginPage())
        }

        Router.init()
    }

    private onLoggedInEvent() {
        this.show(new MainPage())
        CampaignListView.show()
        location.reload()
    }

    public show(view: HTMLElement) {
        this.viewContainer.innerHTML = ""
        this.viewContainer.appendChild(view)
    }
}

document.addEventListener("DOMContentLoaded", () => new App().start())