import JsonUtil from './JsonUtil'

export default class RestResponse<T>{

    public entity: T | undefined

    public statusCode: number

    public httpResponse?: Response

    public static async from<T>(response: Response): Promise<RestResponse<T>> {
        const entity = await this.tryParseEntity<T>(response)
        return new RestResponse<T>(response, entity)
    }

    public constructor(response: Response | undefined, entity: T | undefined) {
        this.httpResponse = response
        this.statusCode = response?.status ?? 500
        this.entity = entity
    }

    private static async tryParseEntity<T>(response: Response): Promise<T | undefined> {
        try {
            const jsonString = await response.text()
            return JSON.parse(jsonString, JsonUtil.parseByRegex) as T
        } catch {
            return undefined
        }
    }

    public isSuccessful() {
        return (this.statusCode === 200 && !!this.entity) || this.statusCode >= 201 && this.statusCode < 300
    }
}