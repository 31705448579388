import Router from 'Router'

export type ViewCreatorFunction = (params: any) => HTMLElement


export function View(customElement: string, url: string, viewCreator: ViewCreatorFunction) {
    Router.register(url, viewCreator)
    return function (constructor: any) {
        customElements.define(customElement, constructor)
    }
}