import MessageBox from 'base/MessageBox'
import YesNoDialog from 'base/YesNoDialog'
import { html, render } from 'lit-html'
import Client from 'rest/Client'
import Router from 'Router'
import { View } from 'view/Views'
import CampaignListView from './CampaignListView'

const URL = "/campaigns/:id/mapping/summary"

@View("mo-campaign-summary", URL, CampaignSummaryView.create)
export default class CampaignSummaryView extends HTMLElement {

    public static show(campaingId: number) {
        Router.navigate(URL, { id: campaingId })
    }

    static create(params: any) {
        return new CampaignSummaryView(params.id)
    }

    constructor(private campaignId?: number) {
        super()
    }

    public connectedCallback() {
        this.renderElement()
    }

    private renderElement() {
        render(html`
<div class="kt-content">
    <div class="kt-subheader--transparent  kt-grid__item">
        <div class="kt-container ">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    Kampagne anlegen
                </h3>
            </div>
        </div>
    </div>
    <div class="kt-container">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						Zusammenfassung
					</h3>
				</div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-grid kt-wizard-v3 kt-wizard-v3--white">
                    <div class="kt-grid__item">
                        <div class="kt-wizard-v3__nav">
                            <div class="kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable">
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="done">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>1</span> Informationen
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="done">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>2</span> Datenupload
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="done">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>3</span> Mapping
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="done">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>4</span> Adressprüfung
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>5</span> Kontrolle & Freigabe
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end: Form Wizard Nav -->
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body">
                <p>Es sind alle Eingaben für die Kampagne vollständig. Sie können diese nun abschließen und an die Direct Mailhouse AG zur Bearbeitung freigeben.</p>
            </div>
            <div class="kt-portlet__foot">
				<div class="row align-items-center">
					<div class="col-lg-12 kt-align-right">
                        <div id="buttons">
                            <button @click=${() => this.onDeleteClicked()} type="submit" class="btn btn-brand">Kampagne Löschen</button>
                            <button @click=${() => this.onNextClicked()} type="submit" class="btn btn-brand">Kampagne freigeben</button>
                        </div>
                        <div class="kt-spinner kt-spinner--sm kt-spinner--brand kt-spinner--right" hidden></div>
					</div>
				</div>
			</div>
        </div>
    </div>
</div>`, this)
    }


    private async onNextClicked() {
        const buttons = this.querySelector<HTMLDivElement>("#buttons")!
        const spinner = this.querySelector<HTMLDivElement>(".kt-spinner")!
        buttons.hidden = true
        spinner.hidden = false

        const response = await Client.post<void>(`campaigns//${this.campaignId}/addresslist/create`, {})
        if (response.isSuccessful() || response.statusCode === 409) {
            CampaignListView.show()
        } else {
            new MessageBox("Fehler", "Es ist ein Fehler beim der Freigabe der Kampagne aufgetreten. Sollte der Fehler weiterhin bestehen, setzen Sie sich bitte mit Ihrem Ansprechpartner in Verbindung.").show()
        }
        buttons.hidden = false
        spinner.hidden = true
    }

    private onDeleteClicked() {
        const dialog = new YesNoDialog("Kampagne löschen", "Möchten Sie die Kampagne wirklich löschen?", () => { this.deleteCampaign() })
        dialog.show()
    }

    private async deleteCampaign() {
        const response = await Client.delete(`campaigns//${this.campaignId}`)
        if (response.isSuccessful()) {
            CampaignListView.show()
        }
    }


}