import ModalDialog from 'base/ModalDialog'
import { TemplateResult, html, render } from 'lit-html'
import { AddressItem } from './CampaignModel'
import Translator from 'i18n/Translator'

export default class AddressDetailDialog extends ModalDialog {


    constructor(private address: AddressItem) {
        super(false)
    }

    public createContent(): TemplateResult {
        return html`
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">${AddressItem.nameString(this.address)}</h5>
        </div>
        <div class="modal-body">
            <p>${this.renderStandardFields()}</p>
            <p>${this.renderAdditionalFields()}</p>
        </div>
        <div class="modal-footer" >
            <button @click=${() => this.close()} type = "button" class="btn btn-secondary" data - dismiss="modal" > Schliessen </button>
        </div>`
    }

    private renderStandardFields() {
        return [
            this.renderAddressElementWithTranslation("addressStandardColumn.first_name", this.address.firstName),
            this.renderAddressElementWithTranslation("addressStandardColumn.last_name", this.address.lastName),
            this.renderAddressElementWithTranslation("addressStandardColumn.full_name", this.address.fullName),
            this.renderAddressElementWithTranslation("addressStandardColumn.company_name", this.address.companyName),
            this.renderAddressElementWithTranslation("addressStandardColumn.company_additional", this.address.companyAdditional),

            this.renderAddressElementWithTranslation("addressStandardColumn.salutation", this.address.salutation),
            this.renderAddressElementWithTranslation("addressStandardColumn.title", this.address.title),

            this.renderAddressElementWithTranslation("addressStandardColumn.street", this.address.street),
            this.renderAddressElementWithTranslation("addressStandardColumn.street_number", this.address.streetNumber),
            this.renderAddressElementWithTranslation("addressStandardColumn.street_and_number", this.address.streetAndNumber),
            this.renderAddressElementWithTranslation("addressStandardColumn.postcode", this.address.postcode),
            this.renderAddressElementWithTranslation("addressStandardColumn.city", this.address.city),
            this.renderAddressElementWithTranslation("addressStandardColumn.postcode_and_city", this.address.postcodeAndCity),
            this.renderAddressElementWithTranslation("addressStandardColumn.post_office_box_number", this.address.postOfficeBoxNumber),
            this.renderAddressElementWithTranslation("addressStandardColumn.country", this.address.country),

            this.renderAddressElementWithTranslation("addressStandardColumn.email", this.address.email),
            this.renderAddressElementWithTranslation("addressStandardColumn.phone", this.address.phone),
            this.renderAddressElementWithTranslation("addressStandardColumn.mobile_phone", this.address.mobilePhone),

            this.renderAddressElementWithTranslation("addressStandardColumn.co_address", this.address.coAddress),
            this.renderAddressElementWithTranslation("addressStandardColumn.letter_salutation", this.address.letterSalutation),
            this.renderAddressElementWithTranslation("addressStandardColumn.customer_number", this.address.customerNumber),
            this.renderAddressElementWithTranslation("addressStandardColumn.language", this.address.language),
            this.renderAddressElementWithTranslation("addressStandardColumn.sort", this.address.sort),

            this.renderAddressElementWithTranslation("addressStandardColumn.custom_char_1", this.address.customChar1),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_char_2", this.address.customChar2),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_char_3", this.address.customChar3),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_char_4", this.address.customChar4),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_char_5", this.address.customChar5),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_char_6", this.address.customChar6),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_char_7", this.address.customChar7),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_char_8", this.address.customChar8),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_char_9", this.address.customChar9),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_char_10", this.address.customChar10),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_num_1", `${this.address.customNum1 ?? ""}`),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_num_2", `${this.address.customNum2 ?? ""}`),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_num_3", `${this.address.customNum3 ?? ""}`),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_num_4", `${this.address.customNum4 ?? ""}`),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_num_5", `${this.address.customNum5 ?? ""}`),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_num_6", `${this.address.customNum6 ?? ""}`),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_num_7", `${this.address.customNum7 ?? ""}`),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_num_8", `${this.address.customNum8 ?? ""}`),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_num_9", `${this.address.customNum9 ?? ""}`),
            this.renderAddressElementWithTranslation("addressStandardColumn.custom_num_10", `${this.address.customNum10 ?? ""}`),
        ]
    }

    private renderAdditionalFields() {
        if (!this.address.additionalField) {
            return
        }
        let result: TemplateResult[] = []
        for (const entry in this.address.additionalField) {
            result.push(this.renderAddressElement(entry, (this.address.additionalField as any)[entry] ?? ""))
        }

        return result
    }

    private renderAddressElementWithTranslation(key: string, value?: string) {
        if (value) {
            return this.renderAddressElement(Translator.translateWithKey(key), value)
        }
        return ""
    }

    private renderAddressElement(key: string, value?: string) {
        return html`<div class="row"><div class="col-sm" style="text-align: right"> <strong>${key}</strong></div><div class="col-sm">${value ?? ""}</div></div>`
    }

}