import { render, html } from 'lit-html'
import { View } from 'view/Views'
import Client from 'rest/Client'
import { AddressCreatedFileResponse, CampaignItem, MappingSummary } from './CampaignModel'
import Router from 'Router'
import CampaignFileSettingsView from './CampaignFileSettingsView'
import CampaignCreateView from './CampaignCreateView'
import CampaignSummaryView from './CampaignSummaryView'

const URL = "/campaigns/:id/file"
@View("mo-campaign-fileupload", URL, CampaignFileUploadView.create)
export default class CampaignFileUploadView extends HTMLElement {

    public static show(campaignId: number) {
        Router.navigate(URL, { id: campaignId })
    }

    private static create(params: any) {
        return new CampaignFileUploadView(params.id)
    }

    constructor(private campaignId?: number) {
        super()
    }

    public connectedCallback() {
        this.createElement()
        this.loadMappings()
    }

    private createElement() {
        render(html`
<div class="kt-content">
    <div class="kt-subheader--transparent  kt-grid__item">
        <div class="kt-container ">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    Kampagne anlegen
                </h3>
            </div>
        </div>
    </div>
    <div class="kt-container">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						Bitte wählen Sie die Adressdatei für die Kampagne aus
					</h3>
				</div>
			</div>
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-grid kt-wizard-v3 kt-wizard-v3--white">
                    <div class="kt-grid__item">
                        <div class="kt-wizard-v3__nav">
                            <div class="kt-wizard-v3__nav-items">
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="done">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>1</span> Informationen
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>2</span> Datenupload
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>3</span> Mapping
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>4</span> Adressprüfung
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>5</span> Kontrolle & Freigabe
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end: Form Wizard Nav -->
                    </div>
                    <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper">
                        <form class="kt-form">
                            <div class="kt-wizard-v3__form">
                                <div class="form-group row">
                                    <label class="col-form-label" data-i18n-innerhtml="campaign.create.name">Datei</label>
                                    <input class="form-control" type="file" id="addressFile" required>
                                </div>

                                <div class="form-group row">
                                    <label class="col-form-label">Mapping</label>
                                    <select id="existingMappings" class="form-control">
                                        <option value="">Neues Mapping erstellen</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__foot">
				<div class="row align-items-center">
					<div class="col-lg-12 kt-align-right">
						<button @click=${() => this.onNextClicked()} type="submit" class="btn btn-brand">Weiter</button>
					</div>
				</div>
			</div>
        </div>
    </div>
</div>`, this)
    }

    private async loadMappings() {
        const response = await Client.get<Array<MappingSummary>>("campaigns//mapped")
        if (response.isSuccessful() && response.entity) {
            const select = this.querySelector("#existingMappings")
            for (const summary of response.entity) {
                const option = document.createElement("option")
                option.value = summary.id.toString()
                option.text = "Kampagne: " + summary.name
                select?.appendChild(option)
            }
        }
    }

    private async onNextClicked() {
        const select = this.querySelector<HTMLSelectElement>("#existingMappings")
        const additionalFields: [string, string][] = []
        if (select?.value) {
            additionalFields.push(["mappingId", select.value])
        }

        let file = this.querySelector<HTMLInputElement>("#addressFile")?.files?.item(0)
        if (!!file) {
            let response = await Client.uploadFile<AddressCreatedFileResponse>(`campaigns//${this.campaignId!}/file`, file, additionalFields)
            if (response.isSuccessful() || response.statusCode === 409) {
                if (additionalFields.length > 0) {
                    CampaignSummaryView.show(this.campaignId!)
                } else {
                    CampaignFileSettingsView.show(this.campaignId!)
                }
            }
        }
    }
}