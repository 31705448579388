import { html, render } from 'lit-html'
import Client from 'rest/Client'
import Router from 'Router'
import { View } from 'view/Views'
import CampaignFileUploadView from './CampaignFileUploadView'
import { CampaignCreateRequest, CampaignCreateResponse } from './CampaignModel'

const URL = "/campaigns/create"
@View("mo-campaign-create", URL, CampaignCreateView.create)
export default class CampaignCreateView extends HTMLElement {

    public static show() {
        Router.navigate(URL)
    }

    private static create() {
        return new CampaignCreateView()
    }

    public connectedCallback() {
        this.createElement()
    }

    private createElement() {
        render(html`
<div class="kt-content">
    <div class="kt-subheader--transparent  kt-grid__item">
        <div class="kt-container ">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    Kampagne anlegen
                </h3>
            </div>
        </div>
    </div>
    <div class="kt-container">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						Wie soll die Kampagne heißen?
					</h3>
				</div>
			</div>
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-grid kt-wizard-v3 kt-wizard-v3--white">
                    <div class="kt-grid__item">
                        <div class="kt-wizard-v3__nav">
                            <div class="kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable">
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>1</span> Informationen
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>2</span> Datenupload
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>3</span> Mapping
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>4</span> Adressprüfung
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>5</span> Kontrolle & Freigabe
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end: Form Wizard Nav -->
                    </div>
                    <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper">
                        <form class="kt-form" style="padding: 4rem 0 0 0">
                            <div class="kt-wizard-v3__form">
                                <div class="form-group row">
                                    <label data-i18n-innerhtml="campaign.create.name">Name der Kampagne</label>
                                    <input class="form-control" type="text" id="campaignName" required>
                                </div>
                                <div class="form-group row">
                                    <label data-i18n-innerhtml="campaign.create.description">Beschreibung</label>
                                    <input class="form-control" type="text" id="campaignDescription" required>
                                </div>
                            </div>
                        </form>                       
                    </div>
                    <div class="row justify-content-center" style="margin:0 0 8px 0">
                        <div class="col-lg-10 col-sm-12"> 
                            <span><strong>Bitte wählen Sie bei Firmenadressen de gewünschten Adressaufbau aus:</strong></span>
                        </div>
                        <div class="col-lg-offset-1 col-lg-5 col-md-6 col-sm-12"> 
                            <div class="card card-custom">
                                <div class="card-body">
                                    <label class="kt-radio kt-radio--bold">
                                        <input type="radio" name="addressVariant" value="PERSONAL">
                                        <span></span><strong>Persönliche Anschrift</strong>
                                    </label>
                                    <p>
                                        <em>Beispiel:</em><br><br>
                                        Herr<br>
                                        Max Muster<br>
                                        DIRECT MAIL HOUSE AG<br>
                                        Mövenstrasse 10<br>
                                        9015 St. Gallen
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12">
                            <div class="card card-custom">
                                <div class="card-body">
                                    <label class="kt-radio kt-radio--bold">
                                        <input type="radio" name="addressVariant" value="COMPANY" checked>
                                        <span></span><strong>Firmenanschrift</strong>
                                    </label>
                                    <p>
                                        <em>Beispiel:</em><br><br>
                                        DIRECT MAIL HOUSE AG<br>
                                        Herr Max Muster<br>
                                        Mövenstrasse 10<br>
                                        9015 St. Gallen<br><br>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__foot">
				<div class="row align-items-center">
					<div class="col-lg-12 kt-align-right">
						<button @click=${() => this.onNextClicked()} type="submit" class="btn btn-brand">Weiter</button>
					</div>
				</div>
			</div>
        </div>
    </div>
</div>`, this)
    }

    private async onNextClicked() {
        let name = this.querySelector<HTMLInputElement>("#campaignName")!.value
        let description = this.querySelector<HTMLInputElement>("#campaignDescription")!.value
        let addressVariant = this.querySelector<HTMLInputElement>(`input[name="addressVariant"]:checked`)?.value
        let response = await Client.post<CampaignCreateResponse>("campaigns", new CampaignCreateRequest(name, description, addressVariant))
        if (response.isSuccessful()) {
            CampaignFileUploadView.show(response.entity!.id)
        }

    }
}
