import DataBinder from 'base/DataBinder'
import { html, render } from 'lit-html'
import Client from 'rest/Client'
import Router from 'Router'
import { View } from "view/Views"
import CampaignMappingView from './CampaignMappingView'
import { ParserConfiguration, ParseResult } from './CampaignModel'


const URL = "/campaigns/:id/file/settings"
@View("mo-campaign-filesettings", URL, CampaignFileSettingsView.create)
export default class CampaignFileSettingsView extends HTMLElement {

    public static show(campaignId: number) {
        Router.navigate(URL, { id: campaignId })
    }

    private static create(params: any) {
        return new CampaignFileSettingsView(params.id)
    }

    constructor(private campaignId?: number) {
        super()
    }

    public connectedCallback() {
        this.renderElement()
        this.loadSettings()
    }
    private parseResult?: ParseResult

    private async loadSettings() {
        let response = await Client.get<ParseResult>(`campaigns//${this.campaignId}/file/settings`)
        if (response.isSuccessful()) {
            this.parseResult = response.entity
            this.renderElement()
            DataBinder.toView(this, this.parseResult)
        }
    }

    private renderElement() {
        render(html`
<div class="kt-content">
    <div class="kt-subheader--transparent  kt-grid__item">
        <div class="kt-container ">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    Kampagne anlegen
                </h3>
            </div>
        </div>
    </div>
    <div class="kt-container">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						Einstellungen für die Addressdatei
					</h3>
				</div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-grid kt-wizard-v3 kt-wizard-v3--white">
                    <div class="kt-grid__item">
                        <div class="kt-wizard-v3__nav">
                            <div class="kt-wizard-v3__nav-items">
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="done">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>1</span> Informationen
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>2</span> Datenupload
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>3</span> Mapping
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>4</span> Adressprüfung
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>5</span> Kontrolle & Freigabe
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end: Form Wizard Nav -->
                    </div>
                    <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper ${this.parseResult?.needsCharset || this.parseResult?.needsDelimiterCharacter || this.parseResult?.needsEscapeCharacter ? "" : "hidden"}">
                        <form class="kt-form">
                            <div class="kt-wizard-v3__form">
                                <div class="form-group row ${this.parseResult?.needsCharset ? "" : "hidden"}">
                                    <label class="col-form-label">Zeichenkodierung</label>
                                    <select class="form-control" data-binding="charset" >
                                        <option value="UTF-8">UTF-8</option>
                                        <option value="ISO-8859-1">ISO-8859-1</option>
                                    </select>
                                </div>
                                <div class="form-group row ${this.parseResult?.needsDelimiterCharacter ? "" : "hidden"}">
                                    <label class="col-form-label">Feldbegrenzer</label>
                                    <input class="form-control" type="text" maxlength="2" data-binding="delimiterCharacter">
                                </div>
                                <div class="form-group row ${this.parseResult?.needsEscapeCharacter ? "" : "hidden"}">
                                    <label class="col-form-label">Feldumschließer</label>
                                    <input class="form-control" type="text" maxlength="2" data-binding="escapeCharacter">
                                </div>
                            </div>
                        </form>
                        <div class="row align-items-center">
                            <div class="col-lg-12 kt-align-right">
                                <button @click=${() => this.onApplyClicked()} type="submit" class="btn btn-brand">Übernehmen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body">
                <h1>Vorschau</h1>
                <div class="row" style="overflow-y: auto">
                    <table id="previewTable" class="table">
                        <thead>
                            <tr>
                                ${this.parseResult?.header.map((value) => html`<th scope="col">${value}</th>`)}
                            </tr>
                        </thead>
                        <tbody id="addressList">
                                ${this.parseResult?.rows.map((value) => html`<tr scope="col">${value.map((valu) => html`<td>${valu}</td>`)}</t>`)}
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="kt-portlet__foot">
				<div class="row align-items-center">
					<div class="col-lg-12 kt-align-right">
						<button @click=${() => this.onNextClicked()} type="submit" class="btn btn-brand">Weiter</button>
					</div>
				</div>
			</div>
        </div>
    </div>
</div>`, this)
    }

    private async onApplyClicked() {
        let configuration = new ParserConfiguration()
        configuration.charset = this.querySelector<HTMLSelectElement>("[data-binding=charset]")!.value
        configuration.delimiterCharacter = this.querySelector<HTMLSelectElement>("[data-binding=delimiterCharacter]")?.value.replace("\\t", "\t") || null
        configuration.escapeCharacter = this.querySelector<HTMLSelectElement>("[data-binding=escapeCharacter]")?.value || null

        let response = await Client.patch<ParseResult>(`/campaigns/${this.campaignId}/file/settings`, configuration)
        if (response.isSuccessful()) {
            this.parseResult = response.entity
            this.renderElement()
        }
    }

    private onNextClicked() {
        CampaignMappingView.show(this.campaignId!)
    }
}