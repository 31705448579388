import { render, html, TemplateResult } from 'lit-html'
import $ from "jquery"
import Translator from 'i18n/Translator'

export default abstract class ModalDialog {

    protected container?: HTMLElement

    constructor(private closable = true) {

    }

    public show() {
        this.createDialog()
        this.initialize(this.container!)
        $(".modal").modal({ backdrop: this.closable ? true : "static", show: true }).on("hidden.bs.modal", () => this.cleanup())
    }

    private createDialog() {
        this.container = document.createElement("div")
        render(html`
        <div id="modalDialog" class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    ${this.createContent()}
                </div>
            </div>
        </div>
        `, this.container)

        Translator.translate(this.container)

        document.body.appendChild(this.container)
    }

    abstract createContent(): TemplateResult

    initialize(element: HTMLElement) {
        //may do some work in subclasses
    }

    private cleanup() {
        if (this.container) {
            document.body.removeChild(this.container)
            this.container = undefined
        }
    }

    public close() {
        $(".modal").modal("hide")
    }
}