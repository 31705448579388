export class CampaignStatus {
    static CREATED = "CREATED"
    static FILE_UPLOADED = "FILE_UPLOADED"
    static VALUE_MAPPING = "VALUE_MAPPING"
    static MAPPING_DONE = "MAPPING_DONE"
    static READY_TO_EXPORT = "READY_TO_EXPORT"
    static EXPORTED = "EXPORTED"
}

export class CampaignItem {
    public id!: number
    public name!: string
    public description?: string
    public status!: string
    public addressCount!: number
    public createdDateTime!: string
}

export class CampaignCreateRequest {
    constructor(public name: string, public description?: string, public addressVariant?: string) {

    }
}

export class CampaignCreateResponse {
    public id!: number
}

export class AddressCreatedFileResponse {

    public addressFileId!: number
    public preview!: ParseResult

}

export class ParseResult {
    public fileName!: string
    public header!: string[]
    public rows!: string[][]
    public needsCharset!: boolean
    public charset?: string
    public needsDelimiterCharacter!: boolean
    public delimiterCharacter?: string
    public needsEscapeCharacter!: boolean
    public escapeCharacter?: string
}

export class ParserConfiguration {
    public charset!: string
    public delimiterCharacter?: string | null
    public escapeCharacter?: string | null
}

export class StandardColumnItem {
    public columnId!: string
    public nameKey!: string

}

export class MappingSummary {
    public id!: number
    public name!: string
}

export class MappingInitialInfo {
    public header!: string[]
    public previewColumns!: string[]
    public standardColumns!: StandardColumnItem[]
}

export class MappedColumnItem {
    public sourceColumnName!: string
    public destinationColumnName?: string
    public destinationStandardColumnId?: string
}

export class CreateMappingRequest {
    public name!: string
    public columns!: MappedColumnItem[]
}

export class CreateMappingResponse {
    public id!: number
    public name!: string
    public needsFieldValueMapping!: boolean
}

export class CampaignAddressesResponse {
    public campaign!: CampaignItem
    public addressList?: AddressListItem
}

export class AddressListItem {
    public originalFileName!: string
    public addressCount!: number
    public addresses?: AddressItem[]
}

export class AddressItem {
    public companyName?: string
    public salutation?: string
    public firstName?: string
    public lastName?: string
    public street?: string
    public streetNumber?: string
    public postOfficeBoxNumber?: string
    public postcode?: string
    public city?: string
    public language?: string
    public fullName?: string
    public streetAndNumber?: string
    public postcodeAndCity?: string
    public customerNumber?: string
    public sort?: string
    public additionalField?: Map<string, string>
    public customChar1?: string
    public customChar2?: string
    public customChar3?: string
    public customChar4?: string
    public customChar5?: string
    public customChar6?: string
    public customChar7?: string
    public customChar8?: string
    public customChar9?: string
    public customChar10?: string
    public customNum1?: number
    public customNum2?: number
    public customNum3?: number
    public customNum4?: number
    public customNum5?: number
    public customNum6?: number
    public customNum7?: number
    public customNum8?: number
    public customNum9?: number
    public customNum10?: number

    public title?: string
    public companyAdditional?: string
    public coAddress?: string
    public country?: string
    public email?: string
    public phone?: string
    public mobilePhone?: string
    public letterSalutation?: string

    public static nameString(address: AddressItem): string {
        return address.fullName || (address.firstName || "") + " " + (address.lastName || "")
    }
}

export class MappableFieldValues {
    public salutationValues?: string[]
    public languageValues?: string[]
}

export class FieldValueMapping {
    public originalValue!: string
    public mappedValue!: string
}

export class FieldValueMappingRequest {
    public salutationMappings?: FieldValueMapping[]
    public languageMappings?: FieldValueMapping[]
}