import { html, render } from 'lit-html'
import Client from 'rest/Client'
import Router from 'Router'
import { View } from 'view/Views'
import { CreateMappingRequest, FieldValueMapping, FieldValueMappingRequest, MappableFieldValues, MappedColumnItem, MappingInitialInfo as MappingInformation } from "./CampaignModel"
import CampaignSummaryView from './CampaignSummaryView'

const URL = "/campaigns/:id/mapping/values"
@View("mo-campaign-value-mapping", URL, CampaignValueMappingView.create)
export default class CampaignValueMappingView extends HTMLElement {

    public static show(campaingId: number) {
        Router.navigate(URL, { id: campaingId })
    }

    static create(params: any) {
        return new CampaignValueMappingView(params.id)
    }

    constructor(private campaignId?: number) {
        super()
    }

    public connectedCallback() {
        this.renderElement()
        this.loadMappingInformation()
    }

    private mappableValues?: MappableFieldValues

    private mappings: Array<MappedColumnItem> = []

    private async loadMappingInformation() {
        let response = await Client.get<MappableFieldValues>(`campaigns//${this.campaignId}/mapping/values`)
        if (response.isSuccessful()) {
            this.mappableValues = response.entity
            this.renderElement()
        }
    }

    private renderElement() {
        render(html`
        <div class="kt-content">
            <div class="kt-subheader--transparent  kt-grid__item">
                <div class="kt-container ">
                    <div class="kt-subheader__main">
                        <h3 class="kt-subheader__title">
                            Kampagne anlegen
                        </h3>
                    </div>
                </div>
            </div>
            <div class="kt-container">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Wertzuordnungen festlegen
                            </h3>
                        </div>
                    </div>
                    <div class="kt-portlet__body kt-portlet__body--fit">
                        <div class="kt-grid kt-wizard-v3 kt-wizard-v3--white">
                            <div class="kt-grid__item">
                                <div class="kt-wizard-v3__nav">
                                    <div class="kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable">
                                        <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="done">
                                            <div class="kt-wizard-v3__nav-body">
                                                <div class="kt-wizard-v3__nav-label">
                                                    <span>1</span> Informationen
                                                </div>
                                                <div class="kt-wizard-v3__nav-bar"></div>
                                            </div>
                                        </div>
                                        <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="done">
                                            <div class="kt-wizard-v3__nav-body">
                                                <div class="kt-wizard-v3__nav-label">
                                                    <span>2</span> Datenupload
                                                </div>
                                                <div class="kt-wizard-v3__nav-bar"></div>
                                            </div>
                                        </div>
                                        <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
                                            <div class="kt-wizard-v3__nav-body">
                                                <div class="kt-wizard-v3__nav-label">
                                                    <span>3</span> Mapping
                                                </div>
                                                <div class="kt-wizard-v3__nav-bar"></div>
                                            </div>
                                        </div>
                                        <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                            <div class="kt-wizard-v3__nav-body">
                                                <div class="kt-wizard-v3__nav-label">
                                                    <span>4</span> Adressprüfung
                                                </div>
                                                <div class="kt-wizard-v3__nav-bar"></div>
                                            </div>
                                        </div>
                                        <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                            <div class="kt-wizard-v3__nav-body">
                                                <div class="kt-wizard-v3__nav-label">
                                                    <span>5</span> Kontrolle & Freigabe
                                                </div>
                                                <div class="kt-wizard-v3__nav-bar"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end: Form Wizard Nav -->
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <h1>Werte zuordnen</h1>
                        ${this.renderSalutationMapping()}
                        ${this.renderLanguageMapping()}
                    </div>
                    <div class="kt-portlet__foot">
                        <div class="row align-items-center">
                            <div class="col-lg-12 kt-align-right">
                                <button @click=${() => this.onNextClicked()} type="submit" class="btn btn-brand">Weiter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`, this)
    }

    private renderSalutationMapping() {
        if (!this.mappableValues?.salutationValues) {
            return html``
        }

        return html`
            <h3>Anrede</h3>
            <table id="salutationTable" class="table">
                <thead>
                    <tr>
                        <th scope="col">Hochgeladener Wert</th>
                        <th scope="col">Zuordnung</th>
                    </tr>
                </thead>
                <tbody id="campaignList">
                    ${this.mappableValues?.salutationValues?.map((value, index) => this.renderSalutationMapppingRow(value, index))}
                </tbody>
            </table>`
    }

    private renderSalutationMapppingRow(header: string, index: number) {
        return html`
        <tr>
            <td style="width: 400px">${header}</td>
            <td>
                <select id="salutation_mapping_${index}" class="form-control" style="width: 300px">
                    <option value=""></option>
                    <option value="1">Herr</option>
                    <option value="2">Frau</option>
                    <option value="5">Familie</option>
                    <option value="3">Herr + Frau</option>
                </select>
            </td>
        </tr>`
    }

    private renderLanguageMapping() {
        if (!this.mappableValues?.languageValues) {
            return html``
        }

        return html`
            <h3>Sprache</h3>
            <table id="languageTable" class="table">
                <thead>
                    <tr>
                        <th scope="col">Hochgeladener Wert</th>
                        <th scope="col">Zuordnung</th>
                    </tr>
                </thead>
                <tbody id="campaignList">
                    ${this.mappableValues?.languageValues?.map((value, index) => this.renderLanguageMapppingRow(value, index))}
                </tbody>
            </table>`
    }

    private renderLanguageMapppingRow(header: string, index: number) {
        return html`
        <tr>
            <td style="width: 400px">${header}</td>
            <td>
                <select id="language_mapping_${index}" class="form-control" style="width: 300px">
                    <option value=""></option>
                    <option value="D">Deutsch</option>
                    <option value="E">Englisch</option>
                    <option value="F">Französisch</option>
                    <option value="I">Italienisch</option>
                </select>
            </td>
        </tr>`
    }

    private async onNextClicked() {
        const request: FieldValueMappingRequest = { salutationMappings: this.getSalutationFieldValueMappings(), languageMappings: this.getLanguageFieldValueMappings() }
        let response = await Client.post<MappingInformation>(`campaigns//${this.campaignId}/mapping/values`, request)
        if (response.isSuccessful() || response.statusCode === 409) {
            CampaignSummaryView.show(this.campaignId!)
        }
    }

    private getSalutationFieldValueMappings(): FieldValueMapping[] {
        if (!this.mappableValues?.salutationValues) {
            return []
        }

        const mappings: FieldValueMapping[] = []
        this.mappableValues?.salutationValues.forEach((value, index) => {
            const mappedValue = this.querySelector<HTMLSelectElement>("#salutation_mapping_" + index)?.value ?? ""
            mappings.push({ originalValue: value, mappedValue })
        })

        return mappings
    }

    private getLanguageFieldValueMappings(): FieldValueMapping[] {
        if (!this.mappableValues?.languageValues) {
            return []
        }

        const mappings: FieldValueMapping[] = []
        this.mappableValues?.languageValues.forEach((value, index) => {
            const mappedValue = this.querySelector<HTMLSelectElement>("#language_mapping_" + index)?.value ?? ""
            mappings.push({ originalValue: value, mappedValue })
        })

        return mappings
    }


}