import UserItem from './UserItem'

export class AuthenticationRequest {
    constructor(public readonly username: string, public readonly password: string) {

    }
}

export class AuthenticationResponse {
    public readonly token!: string
    public user!: UserItem
}