import ModalDialog from 'base/ModalDialog'
import { TemplateResult, html } from 'lit-html'
import { StandardColumnItem, MappedColumnItem } from './CampaignModel'
import Translator from 'i18n/Translator'

export default class CampaignMappingColumnDialog extends ModalDialog {


    constructor(private headerName: string, private standardColumns: StandardColumnItem[], private callback: (item: MappedColumnItem) => void) {
        super(true)
    }

    createContent(): TemplateResult {
        return html`
        <div class="modal-header">
            <h5 class="modal-title">${this.headerName}</h5>
        </div>
        <div class="modal-body">
            <h5>Manuelle Feldzuweisung</h5>
            <div class="input-group mb-3">
                <input id="manualMappingInput" type="text" class="form-control" placeholder="Neuer Feldname" aria-label="Neuer Feldname">
                <div class="input-group-append">
                    <button class="btn btn-outline-brand" type="button" @click=${() => this.onManualMapping()}>Übernehmen</button>
                </div>
            </div>
            <h5>Standardfelder</h5>
            <div class="list-group">
                ${this.standardColumns.map(column => this.renderColumnLine(column))}
            </div>
        </div>`
    }

    private renderColumnLine(column: StandardColumnItem) {
        return html`
            <button @click=${() => this.onColumnSelected(column)} class="list-group-item list-group-item-action"> ${Translator.translateWithKey(column.nameKey)}</button>
        `
    }

    private onColumnSelected(column: StandardColumnItem) {
        const mappedColumn: MappedColumnItem = { sourceColumnName: this.headerName, destinationStandardColumnId: column.columnId }
        this.callback.call(this, mappedColumn)
        this.close()
    }

    private onManualMapping() {
        const newFieldName = this.container?.querySelector<HTMLInputElement>("#manualMappingInput")?.value
        if (newFieldName) {
            const mappedColumn: MappedColumnItem = { sourceColumnName: this.headerName, destinationColumnName: newFieldName }
            this.callback.call(this, mappedColumn)
            this.close()
        }
    }

}