import Client from 'rest/Client'
import Session from 'Session'
import { AuthenticationRequest, AuthenticationResponse } from './LoginModel'
import html from "./LoginPage.html"
import Router from 'Router'

export default class LoginPage extends HTMLElement {

    private dfUsername!: HTMLInputElement
    private dfPassword!: HTMLInputElement
    private cbRememberUsername!: HTMLInputElement
    private btnSubmit!: HTMLButtonElement

    public connectedCallback() {
        this.innerHTML = html

        this.bindViewElements()
        this.handleSavedUsername()
    }

    private bindViewElements() {
        this.dfUsername = this.querySelector<HTMLInputElement>("#dfUsername")!
        this.dfPassword = this.querySelector<HTMLInputElement>("#dfPassword")!
        this.cbRememberUsername = this.querySelector<HTMLInputElement>("#cbRememberUsername")!
        this.btnSubmit = this.querySelector<HTMLButtonElement>("#submit")!
        this.btnSubmit.addEventListener("click", () => this.onLoginClicked())
    }

    private handleSavedUsername() {
        const savedUsername = localStorage.getItem("username")
        if (savedUsername) {
            this.dfUsername.value = savedUsername
            this.cbRememberUsername.checked = true
        }
    }

    private async onLoginClicked() {
        const valid = this.querySelector<HTMLFormElement>("#form")!.checkValidity()
        if (!valid)
            return

        if (this.cbRememberUsername.checked) {
            localStorage.setItem("username", this.dfUsername.value)
        } else {
            localStorage.removeItem("username")
        }

        this.btnSubmit.disabled = true
        await this.login()
        this.btnSubmit.disabled = false
    }

    private async login() {
        const response = await Client.post<AuthenticationResponse>("login", new AuthenticationRequest(this.dfUsername.value, this.dfPassword.value))
        if (response.isSuccessful()) {
            this.onSuccessfulLogin(response.entity!)
        } else {
            alert("Benutzer oder Kennwort falsch")
        }
    }

    private onSuccessfulLogin(response: AuthenticationResponse) {
        Session.get().init(this.dfUsername.value, response.token, response.user)
        document.dispatchEvent(new Event("loggedIn"))
    }
}

customElements.define("mo-login", LoginPage)