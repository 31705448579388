import { View } from "view/Views"
import Client from 'rest/Client'
import { CampaignItem, CampaignStatus } from './CampaignModel'
import $ from "jquery"
import "bootstrap"
import "datatables.net-bs4"
import { render, html } from "lit-html"
import Router from 'Router'
import CampaignCreateView from './CampaignCreateView'
import Translator from 'i18n/Translator'
import CampaignFileUploadView from './CampaignFileUploadView'
import CampaignSummaryView from './CampaignSummaryView'
import CampaignMappingView from './CampaignMappingView'
import CampaignAddressesView from './CampaignAddressesView'
import CampaignValueMappingView from "./CampaignValueMappingView"

const URL = "/campaigns"
@View("mo-campaign-list", URL, CampaignListView.create)
export default class CampaignListView extends HTMLElement {

    public static show() {
        Router.goto(URL)
    }

    static create() {
        return new CampaignListView()
    }

    private campaigns: CampaignItem[] = []

    public connectedCallback() {
        this.renderElement()

        this.loadCampaigns()
    }

    private renderElement() {
        render(html`
    <div class="kt-body">
        <div class="kt-content">
            <div class="kt-subheader kt-grid__item" id="kt_subheader">
                <div class="kt-container ">
                    <div class="kt-subheader__main">
                        <h3 class="kt-subheader__title">
                            Dashboard&nbsp; </h3>
                    </div>
                    <div class="kt-subheader__toolbar">
                        <div class="kt-subheader__wrapper"> </div>
                        <button type="button" class="btn btn-label-brand btn-bold btn-sm" @click=${() => this.onAddCampaignClicked()}>Neue Kampange erstellen</button>
                    </div>
                </div>
            </div>
            <div class="kt-container">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title"> Laufende Kampagnen </h3>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <table id="campaignTable" class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Bezeichnung</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Anzahl Adressen</th>
                                    <th scope="col">Erstellt</th>
                                    <th scope="col">Aktionen</th>
                                </tr>
                            </thead>
                            <tbody id="campaignList">
                                ${this.campaigns.filter(c => c.status != CampaignStatus.EXPORTED).map(c => this.renderCampaignItem(c))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title"> Archivierte Kampagnen </h3>
                            </div>
                            <div class="kt-portlet__head-toolbar">
                                <button class="btn" type="button" data-toggle="collapse" data-target="#doneCampaignSection" aria-expanded="false" aria-controls="collapseExample">
                                    <i class="fas fa-chevron-up"></i><i class="fas fa-chevron-down"></i>
                                </button>
                            </div>
                        </div>
                    <div class="kt-portlet__body">
                        <div id="doneCampaignSection" class="collapse show">
                            <table id="doneCampaignTable" class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Bezeichnung</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Anzahl Adressen</th>
                                        <th scope="col">Erstellt</th>
                                        <th scope="col">Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody id="doneCampaignList">
                                    ${this.campaigns.filter(c => c.status === CampaignStatus.EXPORTED).map(c => this.renderCampaignItem(c))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
`, this)
    }

    private onAddCampaignClicked() {
        CampaignCreateView.show()
    }

    private async loadCampaigns() {
        const response = await Client.get<CampaignItem[]>("/campaigns")
        if (response.isSuccessful()) {
            this.campaigns = response.entity!
            this.renderElement()
            $("#campaignTable").DataTable()
            $("#doneCampaignTable").DataTable()
        }
    }

    private renderCampaignItem(campaign: CampaignItem) {
        return html`
            <tr>
                <td scope="row">${campaign.name}<br><em>${campaign.description ?? ""}</em></td>
                <td scope="row">${this.getCampaignStatusDescription(campaign)}</td>
                <td scope="row">${campaign.addressCount}</td>
                <td scope="row">${new Date(campaign.createdDateTime).toLocaleDateString()}</td>
                <td>${this.renderCampaignActionColumn(campaign)}</td>
            </tr>`
    }

    private getCampaignStatusActionDescription(campaign: CampaignItem) {
        const key = "campaign.next-action." + campaign.status.toString().toLowerCase()
        return Translator.translateWithKey(key)
    }

    private getCampaignStatusDescription(campaign: CampaignItem) {
        const key = "campaign.status." + campaign.status.toString().toLowerCase()
        return Translator.translateWithKey(key)
    }

    private renderCampaignActionColumn(campaign: CampaignItem) {
        const statusText = this.getCampaignStatusActionDescription(campaign)
        switch (campaign.status) {
            case CampaignStatus.CREATED:
                return this.renderWizardLink(statusText, () => CampaignFileUploadView.show(campaign.id))
            case CampaignStatus.FILE_UPLOADED:
                return this.renderWizardLink(statusText, () => CampaignMappingView.show(campaign.id))
            case CampaignStatus.VALUE_MAPPING:
                return this.renderWizardLink(statusText, () => CampaignValueMappingView.show(campaign.id))
            case CampaignStatus.MAPPING_DONE:
                return this.renderWizardLink(statusText, () => CampaignSummaryView.show(campaign.id))
            case CampaignStatus.READY_TO_EXPORT:
                return this.renderWizardLink(statusText, () => CampaignAddressesView.show(campaign.id))
            case CampaignStatus.EXPORTED:
                return this.renderWizardLink(statusText, () => CampaignAddressesView.show(campaign.id))
            default:
                return "Unbekanter Status"
        }
    }

    private renderWizardLink(text: string, action: () => void) {
        return html`<button class="btn btn-default" @click=${() => action()}>${text} <i class="fas fa-arrow-right"></i></button>`
    }
}