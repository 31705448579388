import UserItem from 'login/UserItem'

export default class Session {
    private static instance: Session

    public static get() {
        if (!this.instance) {
            this.instance = new Session()
        }
        return this.instance
    }

    public username?: string
    public authToken?: string

    public user?: UserItem

    constructor() {
        this.tryRetrieveSesionFromLocalStorage()
    }

    private tryRetrieveSesionFromLocalStorage() {
        if (!!localStorage) {
            const value = localStorage.getItem("session")
            if (value) {
                const sessionEntity = JSON.parse(value)
                this.username = sessionEntity.username
                this.authToken = sessionEntity.authToken
                this.user = sessionEntity.user
            }
        }
    }

    public isValid() {
        return !!this.username && !!this.authToken
    }

    public invalidate() {
        if (!!localStorage) {
            localStorage.removeItem("session")
        }

        this.username = undefined
        this.authToken = undefined
    }

    public init(username: string, authToken: string, user: UserItem) {
        this.username = username
        this.authToken = authToken
        this.user = user
        if (!!localStorage) {
            localStorage.setItem("session", JSON.stringify({ username, authToken, user }))
        }
    }
}