import Router from 'Router'
import { View } from 'view/Views'
import { render, html } from 'lit-html'
import Client from 'rest/Client'
import { CampaignAddressesResponse, AddressItem } from './CampaignModel'
import $ from "jquery"
import "bootstrap"
import "datatables.net-bs4"
import Translator from 'i18n/Translator'
import ModalDialog from 'base/ModalDialog'
import AddressDetailDialog from './AddressDetailDialog'

const URL = "/campaigns/:id/addresses"

@View("mo-campaign-addresses", URL, CampaignAddressesView.create)
export default class CampaignAddressesView extends HTMLElement {

    public static show(campaingId: number) {
        Router.navigate(URL, { id: campaingId })
    }

    static create(params: any) {
        return new CampaignAddressesView(params.id)
    }

    constructor(private campaignId?: number) {
        super()
    }

    public async connectedCallback() {
        await this.loadCampaignSummary()
        this.renderElement()
    }


    private async loadCampaignSummary() {
        const response = await Client.get<CampaignAddressesResponse>(`/campaigns/${this.campaignId}/addresslist`)
        if (response.isSuccessful()) {
            this.campaignAddresses = response.entity
            this.renderElement()

            $("#addressTable").DataTable()
        }
    }

    private campaignAddresses?: CampaignAddressesResponse

    private renderElement() {
        render(html`
    <div class="kt-body">
        <div class="kt-content">
            <div class="kt-subheader kt-grid__item" id="kt_subheader">
                <div class="kt-container ">
                    <div class="kt-subheader__main">
                        <h3 class="kt-subheader__title">
                            Kampagne</h3>
                    </div>
                    <div class="kt-subheader__toolbar">
                        <div class="kt-subheader__wrapper"> </div>
                    </div>
                </div>
            </div>
            <div class="kt-container">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">${this.campaignAddresses?.campaign.name ?? ""} <small>(${this.getCampaignStatusText()})</small></h3>
                    </div>
                    </div>
                    <div class="kt-portlet__body">
                        <table id="addressTable" class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Adresse</th>
                                </tr>
                            </thead>
                            <tbody id="campaignList">
                                ${this.campaignAddresses?.addressList?.addresses?.map(a => this.renderAddressItem(a))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>`, this)
    }

    private getCampaignStatusText() {
        const key = "campaign.status." + this.campaignAddresses?.campaign.status.toString().toLowerCase()
        return Translator.translateWithKey(key)
    }

    private renderAddressItem(address: AddressItem) {
        const name = address.fullName || (address.firstName || "") + " " + (address.lastName || "")
        const company = address.companyName ? html`<br><em>${address.companyName}</em>` : ""

        const addressString = address.streetAndNumber || (address.street || "") + " " + (address.streetNumber || "")
        const addressString2 = address.postcodeAndCity || (address.postcode || "") + " " + (address.city || "")

        return html`
            <tr @click=${() => this.onAddressClicked(address)} class="clickable">
                <td scope="row">${name}${company}</td>
                <td scope="row">${addressString}<br>${addressString2}</td>
            </tr>`
    }

    private onAddressClicked(address: AddressItem) {
        new AddressDetailDialog(address).show()
    }

}
