import Session from "../Session"
import RestResponse from './RestResponse'

declare const process: any

export default class Client {
    private static _instance: Client

    private static get instance(): Client {
        if (!this._instance) {
            this._instance = new Client()
        }
        return this._instance
    }

    public static async get<T>(path: string): Promise<RestResponse<T>> {
        return this.instance.fetch(path, Method.GET)
    }

    public static async put<T>(path: string, body: any): Promise<RestResponse<T>> {
        return this.instance.fetch(path, Method.PUT, body)
    }

    public static async patch<T>(path: string, body: any): Promise<RestResponse<T>> {
        return this.instance.fetch(path, Method.PATCH, body)
    }

    public static async post<T>(path: string, body: any): Promise<RestResponse<T>> {
        return this.instance.fetch(path, Method.POST, body)
    }

    public static async delete(path: string): Promise<RestResponse<void>> {
        return this.instance.fetch(path, Method.DELETE)
    }

    public static async uploadFile<T>(path: string, file: File, additionalFormData: [string, string][] = []): Promise<RestResponse<T>> {
        let headers = new Headers()
        Client.setAuthHeader(headers)

        var data = new FormData()
        data.append('file', file)
        if (!!additionalFormData) {
            additionalFormData.forEach(entry => data.append(entry[0], entry[1]))
        }

        const response = await window.fetch(
            url(path),
            {
                method: Method.POST,
                headers: headers,
                body: data
            })

        if (response.status === 401) {
            Session.get().invalidate()
            document.dispatchEvent(new Event("unauthorized"))
        }

        return await RestResponse.from<T>(response)
    }

    private async fetch<T>(path: string, method: string, body?: any): Promise<RestResponse<T>> {
        try {

            const response = await window.fetch(
                url(path),
                {
                    method: method,
                    headers: this.getHeaders(),
                    body: body ? JSON.stringify(body) : undefined
                })

            if (response.status === 401) {
                Session.get().invalidate()
                document.dispatchEvent(new Event("unauthorized"))
            }

            return await RestResponse.from<T>(response)
        } catch {
            return new RestResponse<T>(undefined, undefined)
        }
    }



    private getHeaders(): Headers {
        let headers = new Headers()
        headers.set("Content-Type", "application/json; charset=utf-8")
        Client.setAuthHeader(headers)
        return headers
    }

    private static setAuthHeader(headers: Headers) {
        if (Session.get().isValid()) {
            headers.set("Authorization", `Bearer ${Session.get().authToken}`)
        }
    }
}

export const url: (path: string) => string = path => {
    if (process.env.NODE_ENV === "development") {
        return `${location.protocol}//localhost:8080/mailingo/api/${path.replace("/", "")}`
    } else {
        return `${location.protocol}//${location.host}/api/api/${path.replace("/", "")}`
    }
}

enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE"
}