import { MappingInitialInfo as MappingInformation, StandardColumnItem, MappedColumnItem, CreateMappingRequest, CreateMappingResponse } from "./CampaignModel"
import Client from 'rest/Client'
import { render, html } from 'lit-html'
import { View } from 'view/Views'
import Translator from 'i18n/Translator'
import CampaignMappingColumnDialog from './CampaignMappingColumnDialog'
import Router from 'Router'
import CampaignSummaryView from './CampaignSummaryView'
import CampaignValueMappingView from "./CampaignValueMappingView"

const URL = "/campaigns/:id/mapping"
@View("mo-campaign-mapping", URL, CampaignMappingView.create)
export default class CampaignMappingView extends HTMLElement {

    public static show(campaingId: number) {
        Router.navigate(URL, { id: campaingId })
    }

    static create(params: any) {
        return new CampaignMappingView(params.id)
    }

    constructor(private campaignId?: number) {
        super()
    }

    public connectedCallback() {
        this.renderElement()
        this.loadMappingInformation()
    }

    private mappingInfo?: MappingInformation

    private mappings: Array<MappedColumnItem> = []

    private useStandardColumns: string[] = []

    private async loadMappingInformation() {
        let response = await Client.get<MappingInformation>(`campaigns//${this.campaignId}/mapping/info`)
        if (response.isSuccessful()) {
            this.mappingInfo = response.entity
            this.mappingInfo?.header.forEach(header => this.mappings.push({ sourceColumnName: header }))
            this.renderElement()
        }
    }

    private renderElement() {
        render(html`
<div class="kt-content">
    <div class="kt-subheader--transparent  kt-grid__item">
        <div class="kt-container ">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    Kampagne anlegen
                </h3>
            </div>
        </div>
    </div>
    <div class="kt-container">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						Feldzuordnungen festlegen
					</h3>
				</div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-grid kt-wizard-v3 kt-wizard-v3--white">
                    <div class="kt-grid__item">
                        <div class="kt-wizard-v3__nav">
                            <div class="kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable">
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="done">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>1</span> Informationen
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="done">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>2</span> Datenupload
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>3</span> Mapping
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>4</span> Adressprüfung
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step" data-ktwizard-state="pending">
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>5</span> Kontrolle & Freigabe
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end: Form Wizard Nav -->
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body">
                <h1>Standardfelder</h1>
                <div>
                    ${this.mappingInfo?.standardColumns.map((value) => this.renderStandardColumns(value))}
                </div>
                <br>
                <h1>Zuordnungen</h1>
                <table id="campaignTable" class="table">
                    <thead>
                        <tr>
                            <th scope="col">Feldname</th>
                            <th scope="col">Zuordnung</th>
                            <th scope="col">Beispiel</th>
                        </tr>
                    </thead>
                    <tbody id="campaignList">
                        ${this.mappingInfo?.header.map((value) => this.renderMapppingRow(value))}
                    </tbody>
                </table>
            </div>
            <div class="kt-portlet__foot">
				<div class="row align-items-center">
					<div class="col-lg-12 kt-align-right">
						<button @click=${() => this.onNextClicked()} type="submit" class="btn btn-brand">Weiter</button>
					</div>
				</div>
			</div>
        </div>
    </div>
</div>`, this)
    }

    private renderMapppingRow(header: string) {
        const index = this.mappingInfo!.header.indexOf(header)
        const mapped = this.isColumnMapped(index)


        return html`
        <tr class="headerRow ${mapped ? "mapped" : ""}"
            @dragover=${(event: Event) => this.onColumnDragEvent(event, index)} 
            @dragleave=${(event: Event) => this.onColumnDragLeaveEvent(event, index)}  
            @drop=${(event: DragEvent) => this.onColumnDropEvent(event, index)} 
            data-header-row-index=${index}>
            <td>${header}</td>
            <td>${mapped ? this.renderMappedColumn(index) : this.renderColumnToMap(index)}</td>
            <td>${this.mappingInfo?.previewColumns[index]}</td>
        </tr>`
    }

    private isColumnMapped(index: number) {
        return !!this.mappings[index].destinationStandardColumnId || !!this.mappings[index].destinationColumnName
    }

    private renderMappedColumn(index: number) {
        const mapping = this.mappings[index]
        let name = mapping.destinationColumnName
        if (!!mapping.destinationStandardColumnId) {
            const standardColumn = this.getStandardColumnById(this.mappings[index].destinationStandardColumnId)!
            name = Translator.translateWithKey(standardColumn.nameKey)
        }

        return html`<button class="btn btn-outline-hover-brand btn-elevate btn-circle btn-icon" @click=${() => this.deleteMapping(index)}><i class="fas fa-unlink"></i></button><strong>  ${name}</strong>`
    }

    private renderColumnToMap(index: number) {

        return html`<button class="btn btn-outline-hover-brand btn-elevate btn-circle btn-icon" @click=${() => this.showMapColumnDialog(index)} > <i class= "fas fa-link" > </i></button > `
    }

    private deleteMapping(index: number) {
        this.mappings[index].destinationColumnName = undefined
        if (!!this.mappings[index].destinationStandardColumnId) {
            const standardColumnIndex = this.useStandardColumns.indexOf(this.mappings[index].destinationStandardColumnId!)
            this.useStandardColumns.splice(standardColumnIndex, 1)
            this.mappings[index].destinationStandardColumnId = undefined
        }

        this.renderElement()
    }

    private showMapColumnDialog(index: number) {
        const dialog = new CampaignMappingColumnDialog(
            this.mappingInfo!.header[index],
            this.mappingInfo!.standardColumns.filter(item => this.useStandardColumns.indexOf(item.columnId) === -1), (item) => {
                this.mappings[index] = item
                if (item.destinationStandardColumnId) {
                    this.useStandardColumns.push(item.destinationStandardColumnId)
                }

                this.renderElement()
            })
        dialog.show()
    }

    private onColumnDragEvent(event: Event, headerIndex: number) {
        if (!this.isColumnMapped(headerIndex)) {
            this.querySelector(`[data-header-row-index='${headerIndex}']`)?.classList.add("dragged")
            event.preventDefault()
        }
    }

    private onColumnDragLeaveEvent(event: Event, headerIndex: number) {
        this.querySelector(`[data-header-row-index='${headerIndex}']`)?.classList.remove("dragged")
        event.preventDefault()
    }

    private onColumnDropEvent(event: DragEvent, headerIndex: number) {
        this.querySelector(`[data-header-row-index='${headerIndex}']`)?.classList.remove("dragged")
        event.preventDefault()

        this.mappings[headerIndex].destinationStandardColumnId = event.dataTransfer?.getData("columnId")
        this.useStandardColumns.push(this.mappings[headerIndex].destinationStandardColumnId!)

        this.renderElement()
    }

    private renderStandardColumns(column: StandardColumnItem) {
        const mapped = this.useStandardColumns.indexOf(column.columnId) > -1
        return html`<span
        @dragstart=${(event: DragEvent) => { event.dataTransfer?.setData("columnId", column.columnId) }}
        draggable=${!mapped}
        class="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill kt-badge--rounded addressStandardColumn ${mapped ? "mapped" : ""}" >
            ${Translator.translateWithKey(column.nameKey)}
        </span>`
    }

    private getStandardColumnById(id?: string) {
        if (!id) {
            return undefined
        }
        return this.mappingInfo?.standardColumns.find((value) => value.columnId === id)
    }

    private async onNextClicked() {
        const request: CreateMappingRequest = { name: "Mapping für Kampagne " + this.campaignId, columns: this.mappings.filter(mapping => !!mapping.destinationStandardColumnId || !!mapping.destinationColumnName) }
        let response = await Client.post<CreateMappingResponse>(`campaigns//${this.campaignId}/mapping`, request)
        if (response.isSuccessful() || response.statusCode === 409) {
            if (response.entity?.needsFieldValueMapping) {
                CampaignValueMappingView.show(this.campaignId!)
            } else {
                CampaignSummaryView.show(this.campaignId!)
            }
        }
    }


}